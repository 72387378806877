@tailwind base;
@tailwind components;
@tailwind utilities;

.header {
    @apply text-4xl font-extrabold text-gray-700;
}

.btn {
    @apply transition duration-300 py-2 w-32 text-white font-semibold rounded-md;
}

.text-input {
    @apply rounded-sm px-4 py-2 mt-2 focus:outline-none bg-gray-200 w-full;
}
